import { useAuth0 } from '@auth0/auth0-react';
import { getOrigin, getSubDomain } from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useOrganization,
  useSanityBaseLanguage,
} from '@guider-global/sanity-hooks';
import { SanityImage, URLQueryParams } from '@guider-global/shared-types';
import {
  Button,
  ButtonStack,
  ButtonStackItem,
  Divider,
  Stack,
  StaticAlert,
  Text,
  TextField,
} from '@guider-global/ui';
import { Collapse, useTheme } from '@mui/material';
import { SessionStorageKeys, useSessionStorageTyped } from 'hooks';
import { useRegisterInvite } from 'hooks/useRegisterInvite';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { validateEmail } from 'utils/validateEmail';

export function RegistrationContainer() {
  // State

  const [email, setEmail] = useState<string>();
  const [error, setError] = useState<boolean>();

  const [verifyEmail, setVerifyEmail] = useState<boolean>(false);

  // Search params

  const [urlSearchParams] = useSearchParams();

  const [, setRedirect] = useSessionStorageTyped<string>(
    SessionStorageKeys.REGISTER_REDIRECT,
  );

  const redirectUrl = urlSearchParams.get(URLQueryParams.REDIRECT);

  // Theme

  const { palette } = useTheme();

  // Auth

  const { loginWithRedirect } = useAuth0();
  const origin = getOrigin();

  // Organization
  const organizationSlug = getSubDomain();
  const { organization } = useOrganization({
    organizationSlug,
  });

  const organizationId = organization?.basic_info?.auth0_organization_id;
  const organizationSSOConfigurations =
    organization?.access?.configuration_array ?? [];
  const organizationName = organization?.basic_info?.name;
  const verifyEmailEnabled = organization?.access.email_verification ?? false;
  const allowLocalRegistration =
    organization?.access?.local_account_registration ?? false;

  // Base Language

  const { getBaseLanguage } = useSanityBaseLanguage({
    organizationSlug,
  });
  const baseLanguage = getBaseLanguage();

  const registrationText = baseLanguage.registration;

  // Handle invites

  const { inviteUrl, handleGetInvite, loading } = useRegisterInvite();

  useEffect(() => {
    if (!inviteUrl) {
      return;
    }
    const invite = new URL(inviteUrl);
    const invitation = invite.searchParams.get('invitation');
    const organizationId = invite.searchParams.get('organization');
    const organizationName = invite.searchParams.get('organization_name');
    if (!invitation || !organizationId || !organizationName) {
      return;
    }

    if (redirectUrl) {
      setRedirect(redirectUrl);
    }

    loginWithRedirect({
      authorizationParams: {
        organization: organizationId,
        invitation,
      },
    });
  }, [inviteUrl, loginWithRedirect, origin, redirectUrl, setRedirect]);

  // SSO registration

  const handleSSORegister = useCallback(
    async (connection: string, organizationId: string) => {
      if (redirectUrl) {
        setRedirect(redirectUrl);
      }

      await loginWithRedirect({
        authorizationParams: {
          connection,
          organization: organizationId,
        },
        async openUrl(url) {
          window.location.href = url;
        },
      });
    },
    [loginWithRedirect, redirectUrl, setRedirect],
  );

  const getSvg = useCallback((image: SanityImage) => {
    const imageSource = buildSanityImageUrl({
      source: image,
      format: 'svg',
      height: 22,
    });

    return <img src={imageSource} height="22px" alt={`SSO Logo`} />;
  }, []);

  const ssoButtons: ButtonStackItem[] = organizationSSOConfigurations.map(
    (config) => {
      return {
        key: `${config.auth0_connection_id}`,
        label: config.button_label,
        color: 'gray',
        variant: 'outlined',
        sx: {
          color: palette.text.primary,
          '&&:hover': { color: palette.info.main },
        },
        startIcon: getSvg(config.image),
        onClick: () =>
          organizationId &&
          handleSSORegister(config.auth0_connection_id, organizationId),
      };
    },
  );

  // Local registration

  useEffect(() => {
    if (!email) {
      setError(false);
    }
  }, [email]);

  const handleEmailRegister = useCallback(
    (email: string | undefined) => {
      if (!email || !organizationId || !organizationName) return;
      if (redirectUrl) {
        setRedirect(redirectUrl);
      }
      if (verifyEmailEnabled) {
        setVerifyEmail(true);
      }
      handleGetInvite({
        organizationId,
        organizationName,
        email,
      });
    },
    [
      handleGetInvite,
      organizationId,
      organizationName,
      setRedirect,
      redirectUrl,
      verifyEmailEnabled,
    ],
  );

  function handleEmail(email: string) {
    setEmail(email);
    const validEmail = validateEmail(email);
    if (!validEmail) {
      return setError(true);
    }
    return setError(false);
  }

  // Handle login

  const handleLogin = useCallback(() => {
    if (redirectUrl) {
      setRedirect(redirectUrl);
    }
    loginWithRedirect({
      authorizationParams: {
        organization: organizationId,
      },
    });
  }, [loginWithRedirect, organizationId, redirectUrl, setRedirect]);

  return (
    <Stack
      direction={'column'}
      justifyContent={'flex-start'}
      width={{ xs: '80%', md: '30%' }}
      pt={{ xs: 4, md: 2 }}
    >
      <Text text={registrationText?.common?.title} variant="h2" />
      {ssoButtons.length > 0 && (
        <ButtonStack direction={'column'} spacing={1.5} buttons={ssoButtons} />
      )}

      {ssoButtons.length > 0 && allowLocalRegistration && (
        <Divider>{registrationText?.common?.divider_text}</Divider>
      )}
      {allowLocalRegistration && (
        <>
          <Collapse in={verifyEmail}>
            <StaticAlert
              sx={{ p: 2 }}
              fullWidth
              fontSize="body2"
              message={
                registrationText?.common?.confirm_email_alert_label ??
                'Please check your emails to continue.'
              }
            />
          </Collapse>
          <Collapse in={!verifyEmail}>
            <TextField
              label={registrationText?.local_accounts?.email_input_label}
              labelSize="xs"
              placeholder={registrationText?.local_accounts?.email_placeholder}
              onChange={(e) => handleEmail(e.target.value)}
              error={error}
              helperText={
                error && registrationText?.local_accounts?.email_error_message
              }
              data-cy={'local-email-registration-field'}
            />
            <Button
              variant="contained"
              label={baseLanguage.globals?.common?.continue_button_label}
              color="info"
              onClick={() => handleEmailRegister(email)}
              disabled={!validateEmail(email)}
              loading={loading}
              fullWidth
              sx={{ mt: 2 }}
              data-cy={'local-email-register-button'}
            />
          </Collapse>
        </>
      )}

      <Stack direction={'row'} alignItems={'center'}>
        <Text
          variant="body1"
          text={registrationText?.common?.have_account_label}
        />
        <Button
          variant="text"
          label={registrationText?.common?.login_button_label}
          onClick={() => handleLogin()}
          data-cy={'registration-sign-in-btn'}
        />
      </Stack>
    </Stack>
  );
}
