import { getSubDomain } from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useOrganization,
  useOrganizationPrograms,
  useSanityBaseLanguage,
} from '@guider-global/sanity-hooks';
import {
  EProgramVariation,
  ICustomField,
  Program,
} from '@guider-global/shared-types';
import {
  ChipProps,
  MainColors,
  ResourceCard,
  Text,
  ToolTip,
} from '@guider-global/ui';
import Star from '@mui/icons-material/Star';
import { Grid } from '@mui/material';
import { Stack } from '@mui/system';
import { interpolate } from 'functions';
import {
  useLocalization,
  useMemberships,
  useProfiles,
  useRelationships,
} from 'hooks';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export type ProgramListContainerProps = {
  value: string;
  hasMultipleProgramVariations: boolean;
};

export function ProgramListContainer({
  value,
  hasMultipleProgramVariations,
}: ProgramListContainerProps) {
  const navigate = useNavigate();
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  // Profile
  const { getProfiles } = useProfiles({});
  const [profile] = getProfiles();
  const profileOrganizationFields =
    profile?.organizationFields as ICustomField[];

  // Memberships
  const { memberships: getMemberships } = useMemberships({});
  const memberships = getMemberships();

  // Organization
  const organizationSlug = getSubDomain();
  const { organization } = useOrganization({ organizationSlug });
  const { localeCode } = useLocalization(organizationSlug);

  const openMatchingEnabled =
    organization?.open_matching?.enable_open_matching ?? false;

  const openMatchingProgramTypeVerb =
    organization?.open_matching?.program_type?.program_type_text?.common?.verb;

  let openMatchingChipColor: MainColors = 'primary';

  if (openMatchingProgramTypeVerb === 'Mentoring') {
    openMatchingChipColor = 'success';
  } else if (openMatchingProgramTypeVerb === 'Coaching') {
    openMatchingChipColor = 'info';
  }

  // Programs
  const { getAuthorizedPrograms } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });

  const authorizedPrograms = getAuthorizedPrograms(
    profileOrganizationFields,
    memberships,
  );

  // Derivations
  const programList = baseLanguage?.programs?.programs_list;

  const ongoing_programs_label = programList?.ongoing_programs_label;
  const ongoing_programs_tooltip_text =
    programList?.ongoing_programs_tooltip_text;
  const cohort_programs_label = programList?.cohort_programs_label;
  const cohort_programs_tooltip_text =
    programList?.cohort_programs_tooltip_text;
  const group_programs_label = programList?.group_programs_label;
  const group_programs_tooltip_text = programList?.group_programs_tooltip_text;
  const you_are_eligible_text = programList?.you_are_eligible_text;

  const { relationships: getRelationships } = useRelationships({});

  const relationships = getRelationships().filter(
    (relationship) => !relationship.isConcluded,
  );

  const guideMemberships = useMemo(
    () =>
      memberships
        .filter((membership) => membership.role === 'guide')
        .map((membership) => {
          const programRelationships = relationships.filter(
            (relationship) =>
              relationship.programSlug === membership.programSlug,
          );

          const [relationshipUserIds] = programRelationships.map(
            (relationship) =>
              relationship?.guideProfiles?.map((profile) => profile.userId),
          );

          const relationshipLength =
            relationshipUserIds?.filter((id) => id === membership.userId)
              .length ?? 0;

          return {
            membership,
            relationships: relationshipLength,
          };
        }),
    [memberships, relationships],
  );

  const traineeMemberships = useMemo(
    () =>
      memberships
        .filter((membership) => membership.role === 'trainee')
        .map((membership) => {
          const programRelationships = relationships.filter(
            (relationship) =>
              relationship.programSlug === membership.programSlug,
          );

          const [relationshipUserIds] = programRelationships.map(
            (relationship) =>
              relationship?.traineeProfiles?.map((profile) => profile.userId),
          );

          const relationshipLength =
            relationshipUserIds?.filter((id) => id === membership.userId)
              .length ?? 0;
          return {
            membership,
            relationships: relationshipLength,
          };
        }),
    [memberships, relationships],
  );

  const getProgramData = useCallback(
    (program: Program) => {
      let relationshipLimitReached;
      let hasGuideMembership;
      let hasGuideMembershipAndRelationships;
      let hasTraineeMembershipWithRelationships;
      let traineeRegistrationText;
      let guideRegistrationText;
      let relationshipLimitReachedText;
      let chipColor: ChipProps['color'];

      const relationships =
        traineeMemberships.find(
          (membership) =>
            membership.membership.programSlug === program.metadata.id.current,
        )?.relationships ?? 0;

      const limit =
        program.relationships.relationship_limits?.trainee_relationship_limit;
      const enabled =
        program.relationships.relationship_limits
          ?.enable_trainee_relationship_limit;

      if (enabled && limit && relationships >= limit) {
        relationshipLimitReached = true;
      } else {
        relationshipLimitReached = false;
      }

      if (
        guideMemberships
          .map((membership: any) => membership.membership.programSlug)
          .includes(program.metadata.id.current)
      ) {
        hasGuideMembership = true;
      } else {
        hasGuideMembership = false;
      }

      if (
        guideMemberships
          .filter((membership) => membership.relationships > 0)
          .map((membership: any) => membership.membership.programSlug)
          .includes(program.metadata.id.current)
      ) {
        hasGuideMembershipAndRelationships = true;
      } else {
        hasGuideMembershipAndRelationships = false;
      }

      if (
        traineeMemberships
          .filter((membership) => membership.relationships > 0)
          .map((membership: any) => membership.membership.programSlug)
          .includes(program.metadata.id.current)
      ) {
        hasTraineeMembershipWithRelationships = true;
      } else {
        hasTraineeMembershipWithRelationships = false;
      }

      if (
        program.program_details?.program_variation === EProgramVariation.Group
      ) {
        traineeRegistrationText = hasTraineeMembershipWithRelationships
          ? program.program_details?.program_type?.program_type_text.variations
              ?.group?.program_actions?.trainee?.find_another_guide_button_label
          : program.program_details?.program_type?.program_type_text.variations
              ?.group?.program_actions?.trainee
              ?.register_as_trainee_button_label;
      } else {
        traineeRegistrationText = hasTraineeMembershipWithRelationships
          ? program.program_details?.program_type?.program_type_text.variations
              ?.individual?.program_actions?.trainee
              ?.find_another_guide_button_label
          : program.program_details?.program_type?.program_type_text.variations
              ?.individual?.registration?.registration_landing_page
              ?.trainee_cta_button_label;
      }

      if (
        program.program_details?.program_variation === EProgramVariation.Group
      ) {
        guideRegistrationText = hasGuideMembershipAndRelationships
          ? program.program_details?.program_type?.program_type_text.variations
              ?.group?.program_actions?.guide?.manage_guide_profile_button_label
          : program.program_details?.program_type?.program_type_text.variations
              ?.group?.program_actions?.guide?.register_as_guide_button_label;
      } else {
        guideRegistrationText = hasGuideMembership
          ? program.program_details?.program_type?.program_type_text.variations
              ?.individual?.program_actions?.guide
              ?.manage_guide_profile_button_label
          : program.program_details?.program_type?.program_type_text.variations
              ?.individual?.registration?.registration_landing_page
              ?.guide_cta_button_label;
      }

      if (
        program.program_details?.program_variation === EProgramVariation.Group
      ) {
        relationshipLimitReachedText = interpolate(
          baseLanguage?.dashboard?.program_memberships?.with_program_memberships
            ?.membership_details?.group_membership?.group_member
            ?.group_limit_reached,
          {
            roleSingular:
              program.program_details?.program_type?.program_type_text.common
                ?.guide?.singular ?? 'Mentor',
          },
          'Group limit reached',
        );
      } else {
        relationshipLimitReachedText = interpolate(
          baseLanguage?.dashboard?.program_memberships?.with_program_memberships
            ?.membership_details?.individual_membership?.trainee
            ?.guide_limit_reached,
          {
            roleSingular:
              program.program_details?.program_type?.program_type_text.common
                ?.guide?.singular ?? 'Mentor',
          },
          'Group limit reached',
        );
      }

      const type =
        program.program_details?.program_type?.program_type_text.common?.verb;

      if (type === 'Mentoring') {
        chipColor = 'success';
      } else if (type === 'Coaching') {
        chipColor = 'info';
      } else {
        chipColor = 'primary';
      }

      return {
        relationshipLimitReached,
        hasGuideMembership,
        hasGuideMembershipAndRelationships,
        hasTraineeMembershipWithRelationships,
        traineeRegistrationText,
        guideRegistrationText,
        relationshipLimitReachedText,
        chipColor,
      };
    },
    [
      baseLanguage?.dashboard?.program_memberships?.with_program_memberships
        ?.membership_details?.group_membership?.group_member
        ?.group_limit_reached,
      baseLanguage?.dashboard?.program_memberships?.with_program_memberships
        ?.membership_details?.individual_membership?.trainee
        ?.guide_limit_reached,
      guideMemberships,
      traineeMemberships,
    ],
  );

  if (!authorizedPrograms) {
    return <></>;
  }

  const cohortPrograms = authorizedPrograms.filter(
    (program) =>
      program.program_details?.program_variation ===
      EProgramVariation.Individual,
  );
  const groupPrograms = authorizedPrograms.filter(
    (program) =>
      program.program_details?.program_variation === EProgramVariation.Group,
  );

  function hideMatchButton() {
    if (!profile?.roles?.includes('trainee')) {
      return true;
    } else if (
      profile?.skills?.length === 0 ||
      profile?.goalCategories?.length === 0
    ) {
      return true;
    }

    return false;
  }

  return (
    <Stack width={{ xs: '90%', md: '70%' }} direction="column">
      {(value === 'all' || value === 'open') && openMatchingEnabled && (
        <Stack direction={'column'} pt={6}>
          <Stack direction={'row'} alignItems={'center'} gap={1} pb={2}>
            <Text text={ongoing_programs_label} variant="h3" p={0} m={0} />
            <ToolTip title={ongoing_programs_tooltip_text} placement="right" />
          </Stack>
          <ResourceCard
            contentDirection="row"
            imageSrc={buildSanityImageUrl({
              source: organization?.open_matching?.details?.image ?? '',
            })}
            title={organization?.open_matching?.details?.title}
            description={organization?.open_matching?.details?.description}
            imagePosition="center"
            sx={{
              height: '100%',
              boxShadow: '0px 4px 30px 0px #4D54640D',
              border: 'none',
            }}
            buttons={[
              {
                key: 'btn-1',
                variant: 'contained',
                label: baseLanguage?.globals?.common?.join_button_label,
                color: 'info',
                onClick: () => navigate('../profile/bio'),
                hidden: profile?.roles?.length === 0 ? false : true,
              },
              {
                key: 'btn-2',
                variant: 'outlined',
                label: baseLanguage?.globals?.common?.edit_profile_button_label,
                color: 'info',
                onClick: () => navigate('../profile/bio'),
                hidden: profile?.roles?.length === 0 ? true : false,
              },
              {
                key: 'btn-3',
                variant: 'contained',
                label: baseLanguage?.globals?.common?.find_a_match_button_label,
                color: 'info',
                onClick: () => navigate('../profile/matches/guide'),
                hidden: hideMatchButton(),
              },
            ]}
            chips={[
              {
                variant: 'outlined',
                position: 'top',
                label: openMatchingProgramTypeVerb ?? '',
                color: openMatchingChipColor,
                sx: {
                  '&.MuiChip-root': {
                    fontWeight: '500',
                    borderRadius: '4px',
                  },
                },
              },
            ]}
          />
        </Stack>
      )}

      {(value === 'all' || value === EProgramVariation.Individual) &&
      cohortPrograms.length > 0 ? (
        <Stack direction={'column'} pt={6}>
          {hasMultipleProgramVariations ? (
            <Stack direction={'row'} alignItems={'center'} gap={1} pb={2}>
              <Text text={cohort_programs_label} variant="h3" p={0} m={0} />
              {cohort_programs_tooltip_text ? (
                <ToolTip
                  title={cohort_programs_tooltip_text}
                  placement="right"
                />
              ) : undefined}
            </Stack>
          ) : undefined}

          <Grid
            container
            spacing={2}
            gridAutoRows={'1fr'}
            gridAutoColumns={'1fr'}
            justifyContent={'space-between'}
          >
            {cohortPrograms.map((program: Program, index) => {
              const programData = getProgramData(program);
              const singularProgram = cohortPrograms?.length === 1 ?? false;

              return (
                <Grid
                  key={`${program.metadata.id.current}-${index}`}
                  item
                  xs={12}
                  md={singularProgram ? 12 : 6}
                >
                  <ResourceCard
                    contentDirection={singularProgram ? 'row' : 'column'}
                    moreInformation={{
                      text: baseLanguage?.globals?.common
                        ?.more_information_label,
                      onClick: () =>
                        navigate(`./${program.metadata.id.current}`),
                    }}
                    imageSrc={buildSanityImageUrl({
                      source: program?.program_details?.program_image ?? '',
                    })}
                    title={program.metadata.program_name}
                    description={program.program_details?.program_description}
                    imagePosition="center"
                    sx={{
                      height: '100%',
                      boxShadow: '0px 4px 30px 0px #4D54640D',
                      border: 'none',
                    }}
                    buttons={[
                      {
                        label: programData.guideRegistrationText,
                        key: `${program.metadata.id.current}-button-1`,
                        variant: 'outlined',
                        color: 'info',
                        onClick: () =>
                          navigate(
                            programData.hasGuideMembership
                              ? `./${program.metadata.id.current}/manage/guide`
                              : `./${program.metadata.id.current}/join/guide`,
                          ),
                        'data-cy': programData.hasGuideMembership
                          ? `${program.metadata.id.current}-guide-manage-button`
                          : `${program.metadata.id.current}-guide-join-button`,
                      },
                      {
                        label: programData.relationshipLimitReached
                          ? programData.relationshipLimitReachedText
                          : programData.traineeRegistrationText,
                        key: `${program.metadata.id.current}-button-2`,
                        variant: 'outlined',
                        color: 'info',
                        onClick: () =>
                          navigate(
                            `./${program.metadata.id.current}/join/trainee`,
                          ),
                        disabled: programData.relationshipLimitReached,
                        'data-cy': `${program.metadata.id.current}-trainee-join-button`,
                      },
                    ]}
                    chips={[
                      {
                        variant: 'outlined',
                        position: 'top',
                        label:
                          program.program_details?.program_type
                            ?.program_type_text.common?.verb ?? '',
                        color: programData.chipColor,
                        sx: {
                          '&.MuiChip-root': {
                            fontWeight: '500',
                            borderRadius: '4px',
                          },
                        },
                      },
                      {
                        position: 'top',
                        label: you_are_eligible_text,
                        color: 'warning',
                        icon: <Star />,
                        variant: 'outlined',
                        hidden: program.metadata.restrict_audience_config
                          ?.restrict_audience
                          ? false
                          : true,
                        sx: {
                          '&.MuiChip-root': {
                            fontWeight: 500,
                            borderRadius: '4px',
                          },
                        },
                      },
                    ]}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      ) : undefined}
      {(value === 'all' || value === EProgramVariation.Group) &&
      groupPrograms.length > 0 ? (
        <Stack direction={'column'} pt={6}>
          {hasMultipleProgramVariations ? (
            <Stack direction={'row'} alignItems={'center'} gap={1} pb={2}>
              <Text text={group_programs_label} variant="h3" p={0} m={0} />
              {group_programs_tooltip_text ? (
                <ToolTip
                  title={group_programs_tooltip_text}
                  placement="right"
                />
              ) : undefined}
            </Stack>
          ) : undefined}

          <Grid
            container
            spacing={2}
            gridAutoRows={'1fr'}
            gridAutoColumns={'1fr'}
            justifyContent={'space-between'}
          >
            {groupPrograms.map((program: Program, index) => {
              const programData = getProgramData(program);
              const singularProgram = groupPrograms?.length === 1 ?? false;

              return (
                <Grid
                  key={`${program.metadata.id.current}-${index}`}
                  item
                  xs={12}
                  md={singularProgram ? 12 : 6}
                >
                  <ResourceCard
                    contentDirection={singularProgram ? 'row' : 'column'}
                    moreInformation={{
                      text: baseLanguage?.globals?.common
                        ?.more_information_label,
                      onClick: () =>
                        navigate(`./${program.metadata.id.current}`),
                    }}
                    imageSrc={buildSanityImageUrl({
                      source: program?.program_details?.program_image ?? '',
                    })}
                    title={program.metadata.program_name}
                    description={program.program_details?.program_description}
                    imagePosition="center"
                    sx={{
                      height: '100%',
                      boxShadow: '0px 4px 30px 0px #4D54640D',
                      border: 'none',
                    }}
                    buttons={[
                      {
                        label: programData.guideRegistrationText,
                        key: `${program.metadata.id.current}-button-1`,
                        variant: 'outlined',
                        color: 'info',
                        onClick: () =>
                          navigate(
                            `./${program.metadata.id.current}/join/guide`,
                          ),
                        'data-cy': programData.hasGuideMembership
                          ? `${program.metadata.id.current}-guide-manage-button`
                          : `${program.metadata.id.current}-guide-join-button`,
                      },
                      {
                        label: programData.relationshipLimitReached
                          ? programData.relationshipLimitReachedText
                          : programData.traineeRegistrationText,
                        key: `${program.metadata.id.current}-button-2`,
                        variant: 'outlined',
                        color: 'info',
                        onClick: () =>
                          navigate(
                            `./${program.metadata.id.current}/join/trainee`,
                          ),
                        disabled: programData.relationshipLimitReached,
                        'data-cy': `${program.metadata.id.current}-trainee-join-button`,
                      },
                    ]}
                    chips={[
                      {
                        variant: 'outlined',
                        position: 'top',
                        label:
                          program.program_details?.program_type
                            ?.program_type_text.common?.verb ?? '',
                        color: programData.chipColor,
                        sx: {
                          '&.MuiChip-root': {
                            fontWeight: '500',
                            borderRadius: '4px',
                          },
                        },
                      },
                      {
                        position: 'top',
                        label: you_are_eligible_text,
                        color: 'warning',
                        icon: <Star />,
                        variant: 'outlined',
                        hidden: program.metadata.restrict_audience_config
                          ?.restrict_audience
                          ? false
                          : true,
                        sx: {
                          '&.MuiChip-root': {
                            fontWeight: 500,
                            borderRadius: '4px',
                          },
                        },
                      },
                    ]}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      ) : undefined}
    </Stack>
  );
}
